<template>
  <div class="pie full">
    <div class="pie_legend pie_legend_left">
      <div
        v-for="(item, i) in leftLegend"
        :key="item.name"
        class="pie_legend_item"
      >
        <span class="pie_legend_label">{{ item.name }}</span>
        <span class="pie_legend_value">{{ item.value }}</span>
        <div
          :style="{ borderColor: leftColors[i] + '4d' }"
          class="pie_legend_pointer"
        >
          <div
            :style="{ backgroundColor: leftColors[i] }"
            class="pie_legend_pointer_inner"
          ></div>
        </div>
      </div>
    </div>
    <div class="pie_chart">
      <BaseChart :option="option" />
    </div>
    <div class="pie_legend pie_legend_right">
      <div
        v-for="(item, i) in rightLegend"
        :key="item.name"
        class="pie_legend_item"
      >
        <span class="pie_legend_label">{{ item.name }}</span>
        <span class="pie_legend_value">{{ item.value }}</span>
        <div
          :style="{ borderColor: rightColors[i] + '4d' }"
          class="pie_legend_pointer"
        >
          <div
            :style="{ backgroundColor: rightColors[i] }"
            class="pie_legend_pointer_inner"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createGap } from '@/utils'
import BaseChart from 'hs-ui/packages/BaseChart'

const colors = [
  '#2B91F9',
  '#FEBA16',
  '#FB5F59',
  '#39F4A8',
  '#08D8ED',
  '#F5FAFF',
]

export default {
  name: 'SixPiecePie',
  components: {
    BaseChart,
  },
  props: {
    list: {
      type: Array,
      default: () => [
        {
          name: '<20岁',
          value: 100,
        },
        {
          name: '20~29岁',
          value: 50,
        },
        {
          name: '30~39岁',
          value: 100,
        },
        {
          name: '40~49岁',
          value: 50,
        },
        {
          name: '50~59岁',
          value: 100,
        },
        {
          name: '>60岁',
          value: 50,
        },
      ],
    },
    icon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      option: {},
      baseOption: {
        color: colors,
        tooltip: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        series: [
          {
            type: 'pie',
            radius: ['42%', '48%'],
            silent: true,
            clockwise: false,
            center: ['50%', '50%'],
            label: {
              show: false,
            },
            itemStyle: {
              borderRadius: 6,
            },
            data: [],
          },
          {
            type: 'pie',
            radius: ['28%', '29.4%'],
            silent: true,
            center: ['50%', '50%'],
            itemStyle: {
              color: 'rgba(129, 194, 255, 1)',
              opacity: 0.25,
            },
            label: {
              show: false,
            },
            data: [100],
          },
          {
            type: 'pie',
            radius: ['54%', '55.4%'],
            silent: true,
            center: ['50%', '50%'],
            itemStyle: {
              color: 'rgba(129, 194, 255, 1)',
              opacity: 0.25,
            },
            label: {
              show: false,
            },
            data: [100],
          },
          {
            type: 'pie',
            radius: ['70%', '71.4%'],
            silent: true,
            center: ['50%', '50%'],
            itemStyle: {
              color: 'rgba(129, 194, 255, 1)',
              opacity: 0.25,
            },
            label: {
              show: false,
            },
            data: [100],
          },
        ],
      },
    }
  },
  computed: {
    leftLegend() {
      return this.list.slice(0, 3)
    },
    rightLegend() {
      return this.list.slice(3)
    },
    leftColors() {
      return colors.slice(0, 3)
    },
    rightColors() {
      return colors.slice(3)
    },
    iconOption() {
      return {
        elements: [
          {
            type: 'image',
            z: 3,
            style: {
              image: this.icon,
              width: 40,
              height: 40,
            },
            left: 'center',
            top: 'center',
          },
        ],
      }
    },
  },
  watch: {
    list: {
      handler(list) {
        const dataArr = [...list]

        const empty = createGap(dataArr)
        for (let i = dataArr.length; i > 0; i--) {
          dataArr.splice(i, 0, empty)
        }

        const option = { ...this.baseOption }
        option.series[0].data = dataArr

        if (this.icon) {
          option.graphic = this.iconOption
        }

        this.option = option
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.pie {
  display: flex;
  box-sizing: border-box;
  padding: 20px;

  &_chart {
    flex: 3;
  }

  &_legend {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_item {
      height: 30px;
      display: flex;
      align-items: center;
      position: relative;
      border-bottom: 1px solid #d4e8fe4d;
    }

    &_label {
      font-size: 12px;
      font-weight: 400;
      color: #6ca6db;
      width: 50px;
    }

    &_value {
      font-size: 14px;
      font-weight: 400;
      color: #d4e8fe;
      flex: 1;
      text-align: right;
    }

    &_pointer {
      position: absolute;
      padding: 4px;
      border: 1px solid #f5faff;
      border-radius: 50%;

      &_inner {
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        left: 6px;
        bottom: -9px;
        content: '';
        width: 1px;
        height: 10px;
        background-color: #d4e8fe4d;
      }
    }

    &_left &_item {
      padding-right: 10px;
    }

    &_right &_item {
      padding-left: 10px;
    }

    &_left &_pointer {
      right: -8px;
    }

    &_right &_pointer {
      left: -8px;
    }
  }
}
</style>
