<template>
  <div class="demo full">
    <div class="block">
      <CountUp :value="num" />
    </div>
    <div class="block">
      <TwoPiecePie :icon="require('exam/assets/性别@2x.png')" />
    </div>
    <div class="block">
      <SixPiecePie :icon="require('exam/assets/年龄@2x.png')" />
    </div>
    <div class="block">
      <PieChart />
    </div>
    <div class="block">
      <LineChart />
    </div>
    <div class="block">
      <BarChart />
    </div>
    <div class="block">
      <ScrollList :list="list">
        <template #default="{ data }">
          <p style="font-size: 20px">{{ data }}</p>
        </template>
      </ScrollList>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 0,
      list: [12, 14, 13, 23, 32, 54, 65, 3213],
    }
  },
  mounted() {
    this.num = 1000
  },
}
</script>

<style lang="scss">
html,
body {
  margin: 0;
}
.demo {
  background-color: #000000;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  color: #fff;

  .block {
    width: 400px;
    height: 200px;
  }
}
</style>
