<template>
  <BaseChart :option="option" class="full" @init="handleChartInit" />
</template>

<script>
import * as echarts from 'echarts'
import BaseChart from 'hs-ui/packages/BaseChart'

export default {
  name: 'LineChart',
  components: {
    BaseChart,
  },
  props: {
    list: {
      type: Array,
      default: () => [
        {
          name: '南湖',
          value: 100,
        },
        {
          name: '秀洲',
          value: 50,
        },
        {
          name: '经开',
          value: 100,
        },
        {
          name: '港区',
          value: 50,
        },
        {
          name: '海宁',
          value: 100,
        },
        {
          name: '桐乡',
          value: 50,
        },
        {
          name: '嘉善',
          value: 50,
        },
        {
          name: '平湖',
          value: 50,
        },
        {
          name: '海盐',
          value: 50,
        },
      ],
    },
  },
  data() {
    return {
      selectedIndex: 0,
      option: {},
      baseOption: {
        tooltip: {
          trigger: 'axis',
          triggerOn: 'none',
          confine: true,
          axisPointer: {
            lineStyle: {
              opacity: 0,
            },
          },
          backgroundColor: 'rgba(0, 32, 68, 0.7)',
          borderWidth: 0,
          padding: 15,
          className: 'tooltip',
          textStyle: {
            color: '#fff',
          },
        },
        grid: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 20,
        },
        xAxis: {
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: '#b9f1ff80',
            },
          },
          axisLabel: {
            color: '#82bccc',
          },
          data: [],
        },
        yAxis: {
          show: false,
        },
        series: [
          {
            type: 'line',
            silent: true,
            selectedMode: true,
            symbol: 'circle',
            symbolSize: 6,
            itemStyle: {
              color: '#2dd0ff',
              opacity: 0,
            },
            lineStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 0,
                  color: '#4facfe',
                },
                {
                  offset: 1,
                  color: '#00f2fe',
                },
              ]),
            },
            areaStyle: {
              color: '#2dd0ff',
              opacity: 0.1,
            },
            select: {
              itemStyle: {
                opacity: 1,
              },
            },
            data: [],
          },
        ],
      },
    }
  },
  created() {
    this.chart = null
  },
  mounted() {
    this.changeSelected(this.selectedIndex)
    let timer = setInterval(() => {
      this.selectedIndex += 1
      if (this.selectedIndex >= this.list.length) this.selectedIndex = 0
      this.changeSelected(this.selectedIndex)
    }, 3000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(timer)
      timer = null
    })
  },
  watch: {
    list: {
      handler(list) {
        this.update(list)
      },
      immediate: true,
    },
  },
  methods: {
    update(list) {
      const option = { ...this.baseOption }
      option.xAxis.data = list.map((x) => x.name)
      option.series[0].data = list.map((x) => x.value)

      this.option = option
    },
    handleChartInit(ins) {
      this.chart = ins
    },
    changeSelected(i) {
      if (!this.chart) return
      this.chart.dispatchAction({
        type: 'select',
        seriesIndex: 0,
        dataIndex: i,
      })
      this.chart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: i,
      })
    },
  },
}
</script>
<style>
.tooltip {
  border-radius: 0 !important;
  box-shadow: 0 3px 8px 5px #00c6ff26 inset, 0 4px 6px #00000033 !important;
}
</style>
