<template>
  <div class="seamless-scroll full">
    <ul
      ref="scrollRef"
      :class="['seamless-scroll-list', orientation]"
      @mouseenter="pauseAnimation"
      @mouseleave="launchAnimation"
    >
      <li v-for="(item, i) in list" :key="i" class="seamless-scroll-item">
        <slot :data="item"></slot>
      </li>
      <template v-if="copyListVisibility">
        <li
          v-for="(item, i) in list"
          :key="'copy' + i"
          class="seamless-scroll-item"
        >
          <slot :data="item"></slot>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
const horizontalAnimation = [
  {
    transform: 'translateX(0)',
  },
  {
    transform: 'translateX(-50%)',
  },
]

const verticalAnimation = [
  {
    transform: 'translateY(0)',
  },
  {
    transform: 'translateY(-50%)',
  },
]

export default {
  name: 'ScrollList',
  props: {
    // 数据列表
    list: {
      type: Array,
      default() {
        return []
      },
    },
    // 滚动方向, 水平horizontal, 垂直vertical
    orientation: {
      type: String,
      validator: function (value) {
        return ['horizontal', 'vertical'].includes(value)
      },
    },
    // 是否反向滚动
    reverse: {
      type: Boolean,
      default: false,
    },
    // 鼠标悬停时是否暂停动画
    hoverStop: {
      type: Boolean,
      default: false,
    },
    // 滚动每一个需要的时间, 单位秒
    waste: {
      type: Number,
      default: 2,
    },
    // list从几项开始滚动
    beginsWith: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    copyListVisibility() {
      return this.beginsWith <= this.list.length
    },
  },
  created() {
    this.animation = null
  },
  mounted() {
    this.launch()
  },
  watch: {
    props() {
      this.launch()
    },
  },
  methods: {
    launch() {
      if (this.animation) {
        this.animation.cancel()
        this.animation = null
      }
      if (this.beginsWith > this.list.length) {
        return
      }
      this.animation = this.$refs.scrollRef.animate(
        this.orientation === 'horizontal'
          ? horizontalAnimation
          : verticalAnimation,
        {
          duration: this.list.length * 1000 * this.waste,
          easing: 'linear',
          iterations: Infinity,
          direction: this.reverse ? 'reverse' : 'normal',
        }
      )
    },

    pauseAnimation() {
      if (!this.hoverStop || !this.animation) return
      this.animation.pause()
    },

    launchAnimation() {
      if (!this.hoverStop || !this.animation) return
      this.animation.play()
    },
  },
}
</script>

<style scoped>
.seamless-scroll {
  overflow: hidden;
}

.seamless-scroll-list {
  overflow: hidden;
}

.seamless-scroll-list.horizontal {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.seamless-scroll-item {
  overflow: hidden;
}
</style>
