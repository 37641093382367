// 引入组件
import Component from './src'

// 提供 install 安装方法，供按需引入
Component.install = function (Vue) {
  // 注册组件
  Vue.component(Component.name, Component)
}
// 暴露组件
export default Component
