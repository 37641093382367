<template>
  <div ref="chart" class="full"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'BaseChart',
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  watch: {
    option: {
      deep: true,
      handler(newVal) {
        this.chart.setOption(newVal)
      },
    },
  },
  created() {
    this.chart = null
  },
  mounted() {
    this.initChart()
    this.$once('hook:beforeDestroy', () => {
      if (this.chart) {
        this.chart.dispose()
        this.chart = null
      }
    })
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.chart)
      this.chart.setOption(this.option)
      this.chart.on('click', (params) => {
        // 自定义点击事件
        this.$emit('onChange', params)
      })
      this.$emit('init', this.chart)
    },
  },
}
</script>
