<template>
  <div class="pie full">
    <div class="pie_chart">
      <BaseChart :option="option" />
    </div>
    <div class="pie_legend">
      <div class="pie_legend_block male">
        <div class="pie_legend_label">{{ male.name }}</div>
        <div class="pie_legend_value">{{ male.value }}</div>
      </div>
      <div class="pie_legend_block female">
        <div class="pie_legend_label">{{ female.name }}</div>
        <div class="pie_legend_value">{{ female.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { createGap } from '@/utils'
import BaseChart from 'hs-ui/packages/BaseChart'

export default {
  name: 'TwoPiecePie',
  components: {
    BaseChart,
  },
  props: {
    list: {
      type: Array,
      default: () => [
        {
          name: '男',
          value: 100,
        },
        {
          name: '女',
          value: 50,
        },
      ],
    },
    icon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      option: {},
      baseOption: {
        color: ['#1487DD', '#00E9FA'],
        tooltip: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            center: ['50%', '50%'],
            radius: ['53%', '63%'],
            hoverAnimation: false,
            label: {
              show: false,
            },
            data: [],
          },
          {
            name: '',
            type: 'pie',
            clockWise: false,
            center: ['50%', '50%'],
            radius: ['37%', '53%'],
            hoverAnimation: false,
            label: {
              show: false,
            },
            itemStyle: {
              color: '#2264A3',
              opacity: 0.5,
            },
            data: [],
          },
          {
            name: '',
            type: 'pie',
            clockWise: false,
            center: ['50%', '50%'],
            radius: ['73%', '83%'],
            hoverAnimation: false,
            label: {
              show: false,
            },
            itemStyle: {
              color: '#2264A3',
              opacity: 0.3,
            },
            data: [100],
          },
        ],
      },
    }
  },
  computed: {
    male() {
      return this.list[0] || {}
    },
    female() {
      return this.list[1] || {}
    },
    iconOption() {
      return {
        elements: [
          {
            type: 'image',
            z: 3,
            style: {
              image: this.icon,
              width: 40,
              height: 40,
            },
            left: 'center',
            top: 'center',
          },
        ],
      }
    },
  },
  watch: {
    list: {
      handler(list) {
        const dataArr = [...list]

        const empty = createGap(dataArr)
        dataArr.splice(1, 0, empty)
        dataArr.push(empty)

        const option = { ...this.baseOption }
        option.series[0].data = dataArr
        option.series[1].data = dataArr

        if (this.icon) {
          option.graphic = this.iconOption
        }

        this.option = option
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.pie {
  display: flex;

  &_chart {
    flex: 1;
  }

  &_legend {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;

    &_block {
      display: flex;

      & + & {
        margin-top: 20px;
      }
    }

    &_label {
      font-size: 14px;
      width: 25px;
      line-height: 24px;
      padding-bottom: 14px;
      border-bottom: 5px solid #1488df;
      color: #1488df;
      margin-right: 5px;
    }

    &_value {
      flex: 1;
      text-align: right;
      color: #ffffff;
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 14px;
      border-bottom: 5px solid #1488df59;
    }

    .female &_label {
      color: #00eafb;
      border-bottom-color: #00eafb;
    }

    .female &_value {
      border-bottom-color: #00eafb59;
    }
  }
}
</style>
