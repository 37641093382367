<template>
  <span>{{ display.value }}</span>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'CountUp',
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    duration: {
      type: Number,
      default: 2,
    },
    precision: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      display: {
        value: 0,
      },
    }
  },
  watch: {
    value: {
      handler(val) {
        gsap.to(this.display, {
          value: val,
          duration: this.duration,
          onUpdate: () => {
            this.display.value = Number(
              this.display.value.toFixed(this.precision)
            )
          },
        })
      },
      immediate: true,
    },
  },
}
</script>
