import BaseChart from './BaseChart'
import BarChart from './BarChart'
import CountUp from './CountUp'
import LineChart from './LineChart'
import PieChart from './PieChart'
import ScrollList from './ScrollList'
import SixPiecePie from './SixPiecePie'
import TwoPiecePie from './TwoPiecePie'

// 存放组件的数组
// const components = []
const components = [
  BaseChart,
  BarChart,
  CountUp,
  LineChart,
  PieChart,
  ScrollList,
  SixPiecePie,
  TwoPiecePie,
]

// const requireComponent = require.context(
//   // 其组件目录的相对路径
//   './',
//   // 是否查询其子目录
//   true,
//   // 匹配基础组件文件名的正则表达式
//   /^\.(\w*\/){2,}index.js$/
// )
//
// requireComponent.keys().forEach((fileName) => {
//   // 获取组件配置
//   let component = requireComponent(fileName)
//   // 如果这个组件选项是通过 `export default` 导出的，
//   // 那么就会优先使用 `.default`，
//   // 否则回退到使用模块的根。
//   component = component.default || component
//   components.push(component)
// })

// 定义 install 方法，接收 Vue 作为参数。
const install = function (Vue) {
  // 判断是否安装
  if (install.installed) return
  // 遍历 components 数组，来进行全局注册
  components.map((component) => {
    Vue.component(component.name, component)
  })
  install.installed = true
}

// 判断是否是直接引入文件
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default install
export {
  // 导出的对象必须具有 install，才能被 Vue.use() 方法安装
  install,
  BaseChart,
  BarChart,
  CountUp,
  LineChart,
  PieChart,
  ScrollList,
  SixPiecePie,
  TwoPiecePie,
}
