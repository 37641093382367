<template>
  <div class="pie full">
    <div class="pie_chart">
      <BaseChart :option="option" @init="handleChartInit" />
    </div>
    <ul class="pie_legend">
      <li v-for="item in filterData" :key="item.name" class="pie_legend_line">
        <i :style="{ backgroundColor: item.color }" class="pie_legend_color" />
        <span class="pie_legend_label">{{ item.name }}</span>
        <span class="pie_legend_value">{{ getPercent(item.value, sum) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { createGap } from '@/utils'
import BaseChart from 'hs-ui/packages/BaseChart'

const colors = [
  '#02DEFE',
  '#1EEC80',
  '#FFC324',
  '#F44D4D',
  '#F67347',
  '#0183FF',
  '#9B28FE',
  '#FFF224',
  '#5125F3',
]
export default {
  name: 'PieChart',
  components: {
    BaseChart,
  },
  props: {
    list: {
      type: Array,
      default: () => [
        {
          name: '南湖',
          value: 100,
        },
        {
          name: '秀洲',
          value: 50,
        },
        {
          name: '经开',
          value: 100,
        },
        {
          name: '港区',
          value: 50,
        },
        {
          name: '海宁',
          value: 100,
        },
        {
          name: '桐乡',
          value: 50,
        },
        {
          name: '嘉善',
          value: 50,
        },
        {
          name: '平湖',
          value: 50,
        },
        {
          name: '海盐',
          value: 50,
        },
      ],
    },
    size: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      selectedIndex: 0,
      option: {},
      baseOption: {
        color: colors,
        title: {
          text: '',
          textStyle: {
            color: '#76848c',
            fontSize: 14,
          },
          subtext: '',
          subtextStyle: {
            color: '#ffffff',
            fontSize: 24,
            fontWeight: 'bold',
          },
          textAlign: 'center',
          textVerticalAlign: 'middle',
          itemGap: 20,
          top: '40%',
          left: '47%',
        },
        tooltip: {
          show: false,
        },
        angleAxis: {
          type: 'category',
          data: ['', '', '', '', '', '', '', ''],
          z: 10,
          axisLine: {
            lineStyle: {
              color: '#81C2FF',
              opacity: 0.25,
            },
          },
          axisTick: {
            lineStyle: {
              color: '#81C2FF',
              opacity: 0.6,
            },
          },
        },
        polar: {
          radius: '78%',
        },
        radiusAxis: {
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['54%', '66%'],
            silent: true,
            selectedMode: true,
            selectedOffset: 9,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            data: [],
          },
          {
            type: 'pie',
            radius: ['88%', '89.4%'],
            silent: true,
            itemStyle: {
              color: '#81C2FF',
              opacity: 0.25,
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [100],
          },
        ],
      },
    }
  },
  computed: {
    sum() {
      return this.list.reduce((x, y) => {
        return x + y.value
      }, 0)
    },
    filterData() {
      const i = Math.floor(this.selectedIndex / this.size)
      return this.list
        .slice(i * this.size, (i + 1) * this.size)
        .map((x, j) => ({
          ...x,
          color: colors[i * this.size + j],
        }))
    },
  },
  created() {
    this.chart = null
  },
  mounted() {
    this.changeSelected(this.selectedIndex)
    let timer = setInterval(() => {
      this.selectedIndex += 1
      if (this.selectedIndex >= this.list.length) this.selectedIndex = 0
      this.changeSelected(this.selectedIndex)
    }, 3000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(timer)
      timer = null
    })
  },
  watch: {
    list: {
      handler(list) {
        this.update(list, this.selectedIndex)
      },
      immediate: true,
    },
    selectedIndex: {
      handler(idx) {
        this.update(this.list, idx)
      },
      immediate: true,
    },
  },
  methods: {
    update(list, idx) {
      const dataArr = [...list]

      const empty = createGap(dataArr, 1)
      for (let i = dataArr.length; i > 0; i--) {
        dataArr.splice(i, 0, empty)
      }

      const selectedItem = this.list[idx] || {}
      const option = { ...this.baseOption }
      option.series[0].data = dataArr
      option.title.text = selectedItem.name
      option.title.subtext = this.getPercent(selectedItem.value, this.sum)

      this.option = option
    },
    getPercent(value, sum) {
      if (!value || !sum) return '0%'
      return ((value * 100) / sum).toFixed(1) + '%'
    },
    handleChartInit(ins) {
      this.chart = ins
    },
    changeSelected(i) {
      if (!this.chart) return
      this.chart.dispatchAction({
        type: 'select',
        seriesIndex: 0,
        dataIndex: i * 2,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.pie {
  display: flex;

  &_chart {
    flex: 1;
  }

  &_legend {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 12px;

    &_line {
      height: 40px;
      display: flex;
      align-items: center;
    }

    &_color {
      height: 8px;
      width: 8px;
      display: inline-block;
      margin-right: 8px;
    }

    &_label {
      color: #839cb2;
      width: 90px;
      margin-right: 8px;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: bold;
    }

    &_value {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      font-family: 'Din', 'Microsoft YaHei', sans-serif;
    }
  }
}
</style>
